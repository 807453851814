module.exports = [
    {
        index:1,
        id:'10001011011111',
        ID:'33028319900612121X',
        name:'测试001',
        account:'测试账号一',
        mobile:'18888888888',
        weChat:'weixin_account',
        country:'中国',
        countryId:'test00000001',
        province:'浙江省',
        provinceId:'test00000002',
        city:'杭州',
        cityId:'test000000003',
        county:'滨江区',
        countyId:'test0000000004',
        serverCity:'杭州,宁波',
        serverCountry:'中国,俄罗斯',
        contactWay:'联系方式',
        star:2,
        type:2,
        carId:'10012223363101',
        carMsg:'车辆信息展示',
        amount:23,
        price:88,
        status:1,
        point:12,
        afterSales:21,
        loose:19,
        total:56,
        code:10001,
        duration:50,
        contact:'18808088808',
        editPeople:'操作人',
        lastDate:'2018-04-17 14:59:00',
        addDate:'2018-04-17 14:59:00',
        createDate:'2018-04-17 14:59:00',
        startTime:'2018-04-17 14:59:00',
        endTime:'2018-04-17 14:59:00',
        publishDate:'2018-04-17 14:59:00',
        editDate:'2018-04-17 14:59:00',
        time:'2018-04-17 14:59:00',
        reason:'理由',
        orderId:'1022235401011',
        title:'标题',
        url:'http://www.yitu8.com/sidaoyaoqing?1001',
        place:'全球',
        percentage:'30%',
        state:1,
        commissionRate:35,
        clientManager:'老子是经理',
        startDate:'2018-08-08',
        endDate:'2018-09-09',
        remark:'今天能不能按时下班？',
        totalProfit:30,
        totalWorkers:6,
        squareProfit:5,
        number:1000,
        token:'asdf54648asdf4d5',
        gender:1,
    },
];